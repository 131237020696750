import React from "react";
import {
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Button, styled } from "@100mslive/react-ui";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onJoin, enableJoin }) => {
  const localPermissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();

  const handleEndSession = () => {
    hmsActions.endRoom(false, "End Room");
    window.parent.postMessage(
      {
        messageType: "SESSION_ENDED",
      },
      process.env.REACT_APP_MENTOR_SPACES_APP_PUBLIC_URL
    );
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Button
        disabled={!name || !enableJoin}
        onClick={onJoin}
        style={{
          backgroundColor: "#2ca5a9",
          borderColor: "#2ca5a9",
        }}
      >
        {isStreamingKit() ? "Join Studio" : "Join Room"}
      </Button>
      {localPermissions?.endRoom && (
        <Button
          onClick={handleEndSession}
          style={{
            marginTop: 10,
            backgroundColor: "#e14963",
            borderColor: "#e14963",
          }}
        >
          End Session
        </Button>
      )}
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
